












































import { Vue, Component, PropSync } from 'vue-property-decorator';
import ContractTimeoutViewModel
  from '@/vue-app/view-models/components/contracts/timeout/contract-timeout-view-model';

@Component({ name: 'ContractTimeout' })
export default class ContractTimeout extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @PropSync('process', { type: String })
  synced_process!: string;

  close() {
    this.$emit('closeTimeout');
    this.synced_is_open = false;
  }

  view_model = Vue.observable(new ContractTimeoutViewModel());
}

